@tailwind base;
@tailwind components;

.tooltip {
  @apply invisible absolute;
}

.has-tooltip:hover .tooltip {
  @apply visible z-[9999];
}

@tailwind utilities;
